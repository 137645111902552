import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import CountryService from '@/services/country.service'
import ContactSourceService from '@/services/contact-source.service'
import { ContactsFileUploadResponse } from '@/classes/ContactsFileUploadResponse'
import { CsvFileConfig } from '@/models/CsvFileConfig'
import GroupService from '@/services/group.service'
import { Import } from '@/classes/Import.js'
import vMultiselectListbox from 'vue-multiselect-listbox'
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css'
import SyncService from '@/services/sync.service'
import UserService from '@/services/user.service'
import EventBus from '@/util/EventBus'
import ImportService from '@/services/import.service'
import MultiSelect from '@/components/MultiSelect/MultiSelect.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import ContactsTable from './ContactsTable/ContactsTable.vue'
import Preview from './Preview/Preview.vue'

export default {
  name: 'ImportContacts',
  components: {
    HeaderTopDashboard,
    ButtonMultimedia,
    ContactsTable,
    vMultiselectListbox,
    Preview,
    MultiSelect,
  },
  data () {
    return {
      importObject: new Import(),
      contactsFileUploadResponse: null,
      preview: [],
      skipHeader: null,
      delimiter: null,
      stepperStep: 1,
      dataCountry: [],
      dataCountryKeys: [],
      csvFileConfig: new CsvFileConfig(),
      confirmContactConsent: false,
      userGroups: [],
      collisionActionSelected: null,
      importGroups: [],
      newGroups: [],
      newGroupName: '',
      frecuencies: [],
      loadingFirstStepAction: false,
      customFields: [],
      selectedField: null,
      selectedType: '',
      availableFieldsAdvanced: [],
      typeInfoItems: [],
      selectedColumn: null,
      newFieldType: 'string',
      newFieldName: '',
      newFieldColumn: null,
      countries: [],
      maxSyncs: 0,
      syncData: null,
    }
  },
  computed: {
    fileColumns: function () {
      if (!this.contactsFileUploadResponse && this.preview.length === 0) {
        return []
      }
      const numColumns = this.contactsFileUploadResponse ? this.contactsFileUploadResponse.filePreview[0].length : this.preview.length
      const fileColumns = []
      fileColumns.push({ value: null, text: '' })
      for (let i = 1; i < numColumns + 1; i++) {
        fileColumns.push({ value: i, text: this.$t('Columna').toString() + ' ' + i })
      }
      return fileColumns
    },
    requiresMobilePhoneCountry () {
      return !this.importObject.columnsMap?.email && !this.importObject.columnsMap?.landline
    },
    requiresLandlineCountry () {
      return !this.importObject.columnsMap?.email && !this.importObject.columnsMap?.phone
    },
    requiresEmail () {
      return !this.importObject.columnsMap?.phone && !this.importObject.columnsMap?.landline
    },
    requiresCountry () {
      return this.importObject.columnsMap?.phone || this.importObject.columnsMap?.landline
    },
    canAddNewSync () {
      this.maxSyncs = this.$store.getters['auth/getUser'].maxSyncs ?? this.syncData?.maxTotalSyncs ?? 0
      return this.syncData?.userSyncs < this.maxSyncs
    },
    isSyncDisabled () {
      return this.importObject.isSynchronized() && !this.canAddNewSync
    },
  },
  methods: {
    save () {
      ImportService.importContactsFile(this.importObject).then((response) => {
        this.$router.push('/tools/myimports')
      },
      () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Hubo un error al guardar la imporación de contactos').toString())
      })
    },
    firstStepAction () {
      if (this.importObject.isManual()) {
        this.stepperStep = 2
      }

      if (this.importObject.isSynchronized()) {
        this.uploadFile()
      }
    },
    selectedGroups (newGroups) {
      this.importObject.groupsIds = newGroups.map(g => g.id)
    },
    uploadFile () {
      this.loadingFirstStepAction = true
      SyncService.uploadFile(this.importObject)
        .then(
          (response) => {
            if (response.success === true) {
              this.preview = response.preview
              this.stepperStep = 2
              this.importObject.fileName = response.fileName
              this.importObject.originalFilename = response.originalFilename
            } else {
              EventBus.$emit('showAlert', 'danger', this.$t('Hubo un error al importar el achivo'))
            }
            this.loadingFirstStepAction = false
          },
          () => {
            EventBus.$emit('showAlert', 'danger', this.$t('Hubo un error al importar el achivo'))
          },
        )
        .finally(() => {
          this.loadingFirstStepAction = false
        })
    },
    removeGroup (groupName) {
      const index = this.importObject.newGroups.indexOf(groupName)
      if (index > -1) {
        this.importObject.newGroups.splice(index, 1)
      }
    },
    checkAndAddGroup () {
      GroupService.exists({ name: this.newGroupName }).then(() => {
        this.importObject.newGroups.push(this.newGroupName)
        this.newGroupName = ''
      }, () => {
       EventBus.$emit('showAlert', 'danger', this.$t('Ya existe un grupo con el nombre').toString() + ': ' + this.newGroupName)
      })
    },
    getGroups () {
      GroupService.getUsers()
        .then(
          (response) => {
            this.userGroups = response
          },
          () => { },
        )
        .finally(() => {
        })
    },
    validateItem (itemSelected) {
      if (itemSelected.type === 'file') {
        return true
      }
    },
    getCoutries () {
      CountryService.getCountries()
        .then(
          (countries) => {
            for (const key in countries) {
              const element = countries[key]
              this.countries.push({ value: key, text: element })
            }
            this.countries.sort((a, b) => {
              if (a.text < b.text) {
                return -1
              }
              if (a.text > b.text) {
                return 1
              }
              return 0
            })
          },
          () => { },
        )
        .finally(() => {
        })
    },
    selectFile (url) {
      this.csvFileConfig.filePath = url
      return this.sendFile()
    },
    sendFile () {
      if (this.importObject.isSynchronized()) {
        return
      }
      const url = this.importObject.fileName ? 'reparseFile' : 'uploadFile'
      this.assignSkipHeaderAndDelimiter()

      ContactSourceService.sendFile(this.csvFileConfig, url)
        .then(
          (response) => {
            this.contactsFileUploadResponse = new ContactsFileUploadResponse(response.contactsFileUploadResult)
            this.assignCsvFileConfigAndImportObjectProperties()
          },
          () => {
            EventBus.$emit('showAlert', 'danger', this.$t('Hubo un error al subir el archivo').toString())
          },
        )
    },
    assignSkipHeaderAndDelimiter () {
      this.csvFileConfig.skipHeader = this.importObject.skipHeader
      this.csvFileConfig.delimiter = this.importObject.separator
    },
    assignCsvFileConfigAndImportObjectProperties () {
      this.importObject.fileName = this.contactsFileUploadResponse.fileName
      this.importObject.originalFilename = this.contactsFileUploadResponse.originalFilename
      this.importObject.skipHeader = this.contactsFileUploadResponse.skipHeader
      this.importObject.separator = this.contactsFileUploadResponse.delimiter
      this.csvFileConfig.originalFilename = this.contactsFileUploadResponse.originalFilename
      this.csvFileConfig.fileName = this.contactsFileUploadResponse.fileName
      this.csvFileConfig.filePath = this.contactsFileUploadResponse.fileName
    },
    setAvailableFields () {
      UserService.getAvailableFieldsByType()
      .then(
        (response) => {
          const availableFields = response.availableFields
          for (const fieldsGroupKey in availableFields) {
            const fieldsGroup = availableFields[fieldsGroupKey]
            if (fieldsGroupKey !== 'default' && Object.keys(fieldsGroup.items).length > 0) {
              this.customFields.push({ header: fieldsGroup.name })
              this.customFields.push({ divider: true })
              // eslint-disable-next-line max-depth
              for (const fieldKey in fieldsGroup.items) {
                const fieldValue = fieldsGroup.items[fieldKey]
                this.customFields.push({ value: fieldKey, text: fieldValue })
              }
            }
          }
        })


        UserService.getAvailableFieldsAdvancedByType()
        .then(
          (response) => {
            this.availableFieldsAdvanced = response.availableFieldsAdvancedByType
          })
    },
    // eslint-disable-next-line complexity
    existingColumnChange () {
      var type = 'string'
      if (typeof this.availableFieldsAdvanced.optional.items[this.selectedField] !== 'undefined' &&
        typeof this.availableFieldsAdvanced.optional.items[this.selectedField].type !== 'undefined') {
        type = this.availableFieldsAdvanced.optional.items[this.selectedField].type
      } else if (typeof this.availableFieldsAdvanced.custom.items[this.selectedField] !== 'undefined' &&
        typeof this.availableFieldsAdvanced.custom.items[this.selectedField].type !== 'undefined') {
        type = this.availableFieldsAdvanced.custom.items[this.selectedField].type
      }

      var format = ''
      if (typeof this.availableFieldsAdvanced.optional.items[this.selectedField] !== 'undefined' &&
        typeof this.availableFieldsAdvanced.optional.items[this.selectedField].format !== 'undefined') {
        format = this.availableFieldsAdvanced.optional.items[this.selectedField].format
      } else if (typeof this.availableFieldsAdvanced.custom.items[this.selectedField] !== 'undefined' &&
        typeof this.availableFieldsAdvanced.custom.items[this.selectedField].format !== 'undefined') {
        format = this.availableFieldsAdvanced.custom.items[this.selectedField].format
      }

      var field = type
      if (format) {
        field += '_' + format
      }
      this.selectedType = field
    },
    addExistingField () {
      var split = this.selectedType.split('_')
      var type = split[0]
      var format = ''

      if (split.length > 1) {
        format = split[1]
      }

      this.importObject.fieldsMap[this.selectedField] = { col: this.selectedColumn, type: type, format: format }
      this.selectedField = ''
      this.selectedType = ''
      this.selectedColumn = ''
    },
    removeCustomField (key) {
      if (this.importObject.fieldsMap[key]) {
        delete this.importObject.fieldsMap[key]
        this.$forceUpdate()
      }
    },
    trim (str) {
      return str.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')
    },
    normalizeFieldName (name) {
      if (!name) {
        return name
      }
      return this.trim(name.replace(/\s/g, '_').toLowerCase())
    },
    // eslint-disable-next-line complexity
    addNewField () {
      var myRegxp = /^[.a-zA-Z0-9 _-]+$/
      if (myRegxp.test(this.newFieldName) === false) {
        EventBus.$emit('showAlert', 'warning', this.$t('El nombre del nuevo campo personalizado no puede contener caracteres especiales'))
        return
      }

      var name = this.normalizeFieldName(this.newFieldName)

      if (this.availableFieldsAdvanced) {
        for (var i in this.availableFieldsAdvanced) {
          // eslint-disable-next-line max-depth
          if (this.availableFieldsAdvanced[i]) {
            // eslint-disable-next-line max-depth
            for (var j in this.availableFieldsAdvanced[i].items) {
              // eslint-disable-next-line max-depth
              if (this.availableFieldsAdvanced[i].items[j].name) {
                // eslint-disable-next-line max-depth
                if (name.toLowerCase() === this.availableFieldsAdvanced[i].items[j].name) {
                  EventBus.$emit('showAlert', 'warning', this.$t('El nombre del nuevo campo personalizado ya existe'))
                  return
                }
              }
            }
          }
        }
      }

      var split = this.newFieldType.split('_')
      var type = split[0]
      var format = ''
      if (split.length > 1) {
        format = split[1]
      }

      this.importObject.fieldsMap[name] = { col: this.newFieldColumn, type: type, format: format }

      this.newFieldName = ''
      this.newFieldType = 'string'
      this.newFieldColumn = null
    },
    setFrecuencies () {
      this.frecuencies = [
        { id: 30, name: this.$t('30 minutos') },
        { id: 60, name: this.$t('60 minutos') },
        { id: 90, name: this.$t('90 minutos') },
        { id: 120, name: this.$t('120 minutos') },
        { id: 180, name: this.$t('3 horas') },
        { id: 360, name: this.$t('6 horas') },
        { id: 720, name: this.$t('12 horas') },
        { id: 1440, name: this.$t('24 horas') },
        { id: 2880, name: this.$t('2 días') },
        { id: 4320, name: this.$t('3 días') },
        { id: 10080, name: this.$t('7 días') },
        { id: 21600, name: this.$t('15 días') },
        { id: 43200, name: this.$t('30 días') },
      ]
    },
    setTypeInfoItems () {
      this.typeInfoItems = [
        { value: 'date_d-m-Y', text: this.$t('Fecha').toString() + ' (' + this.$t('día') + ' ' + this.$t('mes') + ' ' + this.$t('año') + '), ' + this.$t('ej').toString() + ': 23-11-2017, ' + this.$t('ej').toString() + ': 23/11/2017' },
        { value: 'date_m-d-Y', text: this.$t('Fecha').toString() + ' (' + this.$t('mes') + ' ' + this.$t('día') + ' ' + this.$t('año') + '), ' + this.$t('ej').toString() + ': 11-23-2017, ' + this.$t('ej').toString() + ': 11/23/2017' },
        { value: 'date_Y-m-d', text: this.$t('Fecha').toString() + ' (' + this.$t('año') + ' ' + this.$t('mes') + ' ' + this.$t('día') + '), ' + this.$t('ej').toString() + ': 2017-11-23, ' + this.$t('ej').toString() + ': 2017/11/23' },
        { value: 'string', text: this.$t('Texto').toString() + ', ' + this.$t('ej').toString() + ': ' + this.$t('Esto es un texto').toString() },
        { value: 'decimal', text: this.$t('Entero').toString() + ', ' + this.$t('ej').toString() + ': 10' },
        { value: 'decimal_comma', text: this.$t('Decimal separado por coma').toString() + ', ' + this.$t('ej').toString() + ': 100,00' },
        { value: 'decimal_point', text: this.$t('Decimal separado por punto').toString() + ', ' + this.$t('ej').toString() + ': 10.00' },
      ]
    },
    getRowFieldName: function (key) {
      return this.customFields.find(field => field.value === key)?.text || key
    },
    getRowTypeName: function (row) {
      const typeFormat = row.type + (row.format ? `_${ row.format }` : '')
      return this.typeInfoItems.find(typeInfo => typeInfo.value === typeFormat)?.text || typeFormat
    },
    getSyncData () {
      ImportService.getSyncData()
      .then(syncData => {
        this.syncData = syncData
      })
    },
  },
  created () {
  },
  mounted () {
    this.importObject.id = this.$route.params.id
    this.importObject.type = this.$route.params.type
    if (this.importObject.isSynchronized()) {
      this.getSyncData()
    }

    this.setFrecuencies()
    this.setTypeInfoItems()
    this.getCoutries()
    this.getGroups()
    this.setAvailableFields()

    if (this.importObject.id && this.importObject.isSynchronized()) {
      ImportService.getSyncToEdit({ id: this.importObject.id }).then((response) => {
        const payload = JSON.parse(response.import.payload)
        this.importObject.setData(payload)
        this.$forceUpdate()
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Hubo un error al obtener la sincronización'))
      })
    }
  },
}
